












import Vue from 'vue';
import Component from 'vue-class-component';
import {Components, Utilities} from 'ottuxlib';
import OttWidget from './OttWidget.vue';
import OttawaFooter = Components.OttawaFooter;
import FooterSection = Utilities.FooterSection;

declare module 'vue/types/vue' {
    interface Vue {
        contactMenu: string,
        aboutMenu: string,
        socialMenu: string,
        feedbackMenu: string,
    }
}

/**
 * Ottawa Footer Widget
 * Wraps the Ottawa Footer component in a Ottawa Widget component for shared scope CSS.
 */
@Component({ components: { OttWidget, OttawaFooter } })
export default class OttFooterWidget extends Vue {
    public widgetComponent = 'OttawaFooter';
    private contactSection: FooterSection | null = null;
    private aboutSection: FooterSection | null = null;
    private socialSection: FooterSection | null = null;
    private feedbackSection: FooterSection | null = null;
    private apiServer: string = process.env.VUE_APP_OTTAWA_CA_URL || '';

    public created() {
        // Load any section overrides that may be present in the markup.
        if (typeof this.$root.contactMenu !== 'undefined' && this.$root.contactMenu !== null) {
            this.contactSection = JSON.parse(this.$root.contactMenu);
        }
        if (typeof this.$root.aboutMenu !== 'undefined' && this.$root.aboutMenu !== null) {
            this.aboutSection = JSON.parse(this.$root.aboutMenu);
        }
        if (typeof this.$root.socialMenu !== 'undefined' && this.$root.socialMenu !== null) {
            this.socialSection = JSON.parse(this.$root.socialMenu);
        }
        if (typeof this.$root.feedbackMenu !== 'undefined' && this.$root.feedbackMenu !== null) {
            this.feedbackSection = JSON.parse(this.$root.feedbackMenu);
        }
    }
    public mounted() {
        if (typeof this.$root.contactMenu !== 'undefined' && this.$root.contactMenu !== null) {
            this.contactSection = JSON.parse(this.$root.contactMenu);
        }
        if (typeof this.$root.aboutMenu !== 'undefined' && this.$root.aboutMenu !== null) {
            this.aboutSection = JSON.parse(this.$root.aboutMenu);
        }
        if (typeof this.$root.socialMenu !== 'undefined' && this.$root.socialMenu !== null) {
            this.socialSection = JSON.parse(this.$root.socialMenu);
        }
        if (typeof this.$root.feedbackMenu !== 'undefined' && this.$root.feedbackMenu !== null) {
            this.feedbackSection = JSON.parse(this.$root.feedbackMenu);
        }
    }
}
