










import Vue from 'vue';
import Component from 'vue-class-component';
import OttWidget from './OttWidget.vue';
import {Components} from 'ottuxlib';
import OttawaStatusList = Components.OttawaStatusList;

declare module 'vue/types/vue' {
    interface Vue {
        target: string;
        location: string;
        zindex: string;
    }
}

/**
 * Ottawa Status List Widget
 */
@Component({ components: { OttWidget, OttawaStatusList } })
export default class OttStatusListWidget extends Vue {
    public widgetComponent = 'OttawaStatusList';
    private apiServer: string = process.env.VUE_APP_OTTAWA_CA_URL || '';

    public mounted() {

    }
}
