

















import Vue from 'vue';
import { Component, Mixins } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import OttWidget from './OttWidget.vue';
import {Components, Utilities} from 'ottuxlib';
import OttawaHeader = Components.OttawaHeader;
import MenuLinks = Utilities.MenuLink;
import WebServiceConnectorMixin from './WebServiceConnectorMixin';
import {AxiosResponse} from 'axios';

declare module 'vue/types/vue' {
    interface Vue {
        skipToContentId: string;
        extraLinks: string;
        searchNameAttr: string;
        searchAction: string;
        languageLink: string;
        menuLabel: string;
        homeLinkUrl: string;
        homeLinkTitle: string;
        searchResultsId: string;
        links: string;
    }
}
  // Create union type of mixins.
  interface IGatewayComponent extends WebServiceConnectorMixin {}

/**
 * Ottawa Header Widget
 * Wraps the Ottawa Header component in a Ottawa Widget component for shared scope CSS.
 */
@Component({ components: { OttWidget, OttawaHeader } })
export default class OttHeaderWidget extends Mixins<IGatewayComponent>(WebServiceConnectorMixin) {
      @Prop({ default: '/api/gateway/index' }) apiPath!: string;

      public widgetComponent = 'OttawaHeader';
      private menuLinks: MenuLinks[] = [];
      private gatewayLinks: MenuLinks[] = [];
      private searchTarget = 'https://ottawa.ca/search';
      private searchFieldName = 'searchfield';
      private skipToContent = 'content';
      private languageUrl = '/fr';
      private lgMenuLabel = 'Sign-in';
      private homeUrl = 'https://ottawa.ca';
      private homeTitle = 'Home';
      private searchControls = '';
      private apiServer: string = process.env.VUE_APP_OTTAWA_CA_URL || '';

      // Parse the response into menu links.
      public parseResponse(response: AxiosResponse<MenuLinks[]>) {
          if (typeof response !== "undefined" && response !== null
              && typeof response.data !== "undefined" && response.data !== null) {
              this.gatewayLinks = response.data;
          }
      }

      // Parse the properties. If gateways are provided, use them. If not make a REST call to get the gateways.
      public async created() {
          // Check for locally provided Gateways.
          if (typeof this.$root.links !== 'undefined' && this.$root.links !== null) {
              try {
                  this.gatewayLinks = JSON.parse(this.$root.links);
              } catch(error) {
                  console.error(error.message);
              }
          } else {
              // If not local gateways, call the web service.
              try {
                  await this.makeWebServiceCall(this.apiPath);
              } catch(error) {
                  console.error(error.message);
              }

          }
      }


    public mounted() {
        // Set the skip to main content element id.
        if (typeof this.$root.skipToContentId !== 'undefined' && this.$root.skipToContentId !== null) {
            this.skipToContent = this.$root.skipToContentId;
        }
        // Validate the skip to main content element id.
        if (document && !document.getElementById(this.skipToContent)) {
            console.warn('Could not find the skip to content id.');
        }

        // Set the extra menu links
        if (typeof this.$root.extraLinks !== 'undefined' && this.$root.extraLinks !== null) {
            try {
                  this.menuLinks = JSON.parse(this.$root.extraLinks);
            } catch(error) {
                  console.error(error.message);
            }
        }

        // Set the search target
        if (typeof this.$root.searchAction !== 'undefined' && this.$root.searchAction !== null) {
            this.searchTarget = this.$root.searchAction;
        }

        // Set the search field name
        if (typeof this.$root.searchNameAttr !== 'undefined' && this.$root.searchNameAttr !== null) {
            this.searchFieldName = this.$root.searchNameAttr;
        }

        // Set the language link URL.
        if (typeof this.$root.languageLink !== 'undefined' && this.$root.languageLink !== null) {
            this.languageUrl = this.$root.languageLink;
        }

        // Set the menu label.
        if (typeof this.$root.menuLabel !== 'undefined' && this.$root.menuLabel !== null) {
            this.lgMenuLabel = this.$root.menuLabel;
        }

        // Set the home url.
        if (typeof this.$root.homeLinkUrl !== 'undefined' && this.$root.homeLinkUrl !== null) {
            this.homeUrl = this.$root.homeLinkUrl;
        }

        // Set the home title.
        if (typeof this.$root.homeLinkTitle !== 'undefined' && this.$root.homeLinkTitle !== null) {
            this.homeTitle = this.$root.homeLinkTitle;
        }

        // Set the search controls attribute.
        if (typeof this.$root.searchResultsId !== 'undefined' && this.$root.searchResultsId !== null) {
            this.searchControls = this.$root.searchResultsId;
        }
    }
}
