import {Mixin} from 'vue-mixin-decorator';
import Vue from 'vue';
import axios, { AxiosResponse } from 'axios';

/**
 * DeviceSizeMixin
 * Track the screen size to know what breakpoint we are using.
 */
@Mixin
export default class WebServiceConnectorMixin extends Vue {

    /**
     * This is a stub for the components to implement.
     * @param response
     */
    public parseResponse(response: AxiosResponse) {}

    public async makeWebServiceCall(apiPath: string) {
        // Prepend the language code to the api path.
        const path = `${this.$i18n.locale}${apiPath}`;

        // Get the environment URL.
        let apiUrl: string = '';
        if (typeof process.env.VUE_APP_OTTAWA_CA_URL !== 'undefined'
            && process.env.VUE_APP_OTTAWA_CA_URL !== null) {
            apiUrl = process.env.VUE_APP_OTTAWA_CA_URL;
        }

        // Concatenate the environment URL with the webservice path.
        apiUrl = `${apiUrl}${path}`;

        // Initialize the webservice if there was an api URL given and parse the response.
        if (apiUrl !== path) {
            const response = await axios.get(apiUrl);
            this.parseResponse(response);
        }
    }
}
